export const LOGIN_USING_MOBILE = "LOGIN_USING_MOBILE";
export const DO_LOGIN = "DO_LOGIN";
export const DO_LOGOUT = "DO_LOGOUT";
export const STORE_SELECTED_USER = "STORE_SELECTED_USER";
export const REGISTRATION_USING_AADHAR = "REGISTRATION_USING_AADHAR";

//Login with mobile/Aadhaar
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const AADHAAR_USERS_LIST = "AADHAAR_USERS_LIST";
export const USER_DETAILS = "USER_DETAILS";
export const USER_TOKEN = "USER_TOKEN";
export const LOGOUT_USER = "LOGOUT_USER";

export const LOADING = "LOADING";
export const SEND_OTP = "SEND_OTP";
export const GET_AADHAAR_DATA = "GET_AADHAAR_DATA";
export const SEND_AADHAAR_SMS = "SEND_AADHAAR_SMS";
export const AADHAAR_SMS_KYC = "AADHAAR_SMS_KYC";
export const ERROR = "ERROR";

//PERSONAL_INFO
export const LOADING_PERSONAL_DETAILS = "LOADING_PERSONAL_DETAILS";
export const ERROR_PERSONAL_DETAILS = "ERROR_PERSONAL_DETAILS";


//FAMILY_BENEFITS
export const FAMILY_BENEFITS_AVAILED_REQUEST_ERROR = "FAMILY_BENEFITS_AVAILED_REQUEST_ERROR";
export const FAMILY_BENEFITS_AVAILED_REQUEST_SUCCESS = "FAMILY_BENEFITS_AVAILED_REQUEST_SUCCESS";
export const FAMILY_BENEFITS_AVAILED_REQUEST_START = "FAMILY_BENEFITS_AVAILED_REQUEST_START";

//TOAST
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

export const TRACK_SERVICE_REQUEST_START = "TRACK_SERVICE_REQUEST_START";
export const TRACK_SERVICE_REQUEST_SUCCESS = "TRACK_SERVICE_REQUEST_SUCCESS";
export const TRACK_SERVICE_REQUEST_ERROR = "TRACK_SERVICE_REQUEST_ERROR";
export const TRACK_SERVICE_REQUEST_UPDATE = "TRACK_SERVICE_REQUEST_UPDATE";

export const TRACK_SERVICE_REQUEST_BY_SR_NUMBER_START =
  "TRACK_SERVICE_REQUEST_BY_SR_NUMBER_START";
export const TRACK_SERVICE_REQUEST_BY_SR_NUMBER_SUCCESS =
  "TRACK_SERVICE_REQUEST_BY_SR_NUMBER_SUCCESS";
export const TRACK_SERVICE_REQUEST_BY_SR_NUMBER_ERROR =
  "TRACK_SERVICE_REQUEST_BY_SR_NUMBER_ERROR";

export const GET_USER_PROFILE_REQUEST_START = "GET_USER_PROFILE_REQUEST_START";
export const GET_USER_PROFILE_REQUEST_SUCCESS =
  "GET_USER_PROFILE_REQUEST_SUCCESS";
export const GET_USER_PROFILE_REQUEST_ERROR = "GET_USER_PROFILE_REQUEST_ERROR";

export const GET_MASTER_DATA_REQUEST_START = "GET_MASTER_DATA_REQUEST_START";
export const GET_MASTER_DATA_REQUEST_SUCCESS =
  "GET_MASTER_DATA_REQUEST_SUCCESS";
export const GET_MASTER_DATA_REQUEST_ERROR = "GET_MASTER_DATA_REQUEST_ERROR";

export const GET_USER_DETAILS_START = "GET_USER_DETAILS_START";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";

export const GET_TALUKA_START = "GET_TALUKA_START";
export const GET_TALUKA_SUCCESS = "GET_TALUKA_SUCCESS";
export const GET_TALUKA_ERROR = "GET_TALUKA_ERROR";

export const GET_VILLAGE_START = "GET_VILLAGE_START";
export const GET_VILLAGE_SUCCESS = "GET_VILLAGE_SUCCESS";
export const GET_VILLAGE_ERROR = "GET_VILLAGE_ERROR";

export const UPDATE_USER_PROFILE_START = "UPDATE_USER_PROFILE_START";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";

export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";

// Services Availed
export const SERVICES_AVAILED_REQUEST_START = "SERVICES_AVAILED_REQUEST_START";
export const SERVICES_AVAILED_REQUEST_SUCCESS =
  "SERVICES_AVAILED_REQUEST_SUCCESS";
export const SERVICES_AVAILED_REQUEST_ERROR = "SERVICES_AVAILED_REQUEST_ERROR";
export const SERVICES_AVAILED_REQUEST_UPDATE =
  "SERVICES_AVAILED_REQUEST_UPDATE";

// Services Availed Certificate Details
export const CERTIFICATE_DETAIL_REQUEST_START =
  "CERTIFICATE_DETAIL_REQUEST_START";
export const CERTIFICATE_DETAIL_REQUEST_SUCCESS =
  "CERTIFICATE_DETAIL_REQUEST_SUCCESS";
export const CERTIFICATE_DETAIL_REQUEST_ERROR =
  "CERTIFICATE_DETAIL_REQUEST_ERROR";

// Grievance History
export const GRIEVANCE_HISTORY_REQUEST_START =
  "GRIEVANCE_HISTORY_REQUEST_START";
export const GRIEVANCE_HISTORY_REQUEST_SUCCESS =
  "GRIEVANCE_HISTORY_REQUEST_SUCCESS";
export const GRIEVANCE_HISTORY_REQUEST_ERROR =
  "GRIEVANCE_HISTORY_REQUEST_ERROR";
export const GRIEVANCE_HISTORY_REQUEST_UPDATE =
  "GRIEVANCE_HISTORY_REQUEST_UPDATE";

// Benifits Availed
export const BENEFITS_AVAILED_REQUEST_START = "BENEFITS_AVAILED_REQUEST_START";
export const BENEFITS_AVAILED_REQUEST_SUCCESS =
  "BENEFITS_AVAILED_REQUEST_SUCCESS";
export const BENEFITS_AVAILED_REQUEST_ERROR = "BENEFITS_AVAILED_REQUEST_ERROR";
export const BENEFITS_AVAILED_REQUEST_UPDATE =
  "BENEFITS_AVAILED_REQUEST_UPDATE";

// Scheme Enrolled
export const SCHEME_ENROLLED_REQUEST_START = "SCHEME_ENROLLED_REQUEST_START";
export const SCHEME_ENROLLED_REQUEST_SUCCESS =
  "SCHEME_ENROLLED_REQUEST_SUCCESS";
export const SCHEME_ENROLLED_REQUEST_ERROR = "SCHEME_ENROLLED_REQUEST_ERROR";
export const SCHEME_ENROLLED_REQUEST_UPDATE = "SCHEME_ENROLLED_REQUEST_UPDATE";

//User profile update as Aadhaar
export const USER_PROFILE_UPDATE_AS_AADHAAR_START =
  "USER_PROFILE_UPDATE_AS_AADHAAR_START";
export const USER_PROFILE_UPDATE_AS_AADHAAR_SUCCESS =
  "USER_PROFILE_UPDATE_AS_AADHAAR_SUCCESS";
export const USER_PROFILE_UPDATE_AS_AADHAAR_ERROR =
  "USER_PROFILE_UPDATE_AS_AADHAAR_ERROR";
export const NA_DOCUMENT_SELECTED_REQUEST_SUCCESS =
  "NA_DOCUMENT_SELECTED_REQUEST_SUCCESS";

//updateAddressWithOtherDepartment
export const UPDATE_ADDRESS_WITH_OTHER_DEPARTMENT_START =
  "UPDATE_ADDRESS_WITH_OTHER_DEPARTMENT_START";
export const UPDATE_ADDRESS_WITH_OTHER_DEPARTMENT_SUCCESS =
  "UPDATE_ADDRESS_WITH_OTHER_DEPARTMENT_SUCCESS";
export const UPDATE_ADDRESS_WITH_OTHER_DEPARTMENT_ERROR =
  "UPDATE_ADDRESS_WITH_OTHER_DEPARTMENT_ERROR";

//getFamilyMembersList
export const GET_FAMILY_MEMBERS_LIST_START = "GET_FAMILY_MEMBERS_LIST_START";
export const GET_FAMILY_MEMBERS_LIST_SUCCESS =
  "GET_FAMILY_MEMBERS_LIST_SUCCESS";
export const GET_FAMILY_MEMBERS_LIST_ERROR = "GET_FAMILY_MEMBERS_LIST_ERROR";

// getNews
export const GET_NEWS_DETAILS_START = "GET_NEWS_DETAILS_START";
export const GET_NEWS_DETAILS_SUCCESS = "GET_NEWS_DETAILS_SUCCESS";
export const GET_NEWS_DETAILS_ERROR = "GET_NEWS_DETAILS_ERROR";

// getPortalResource
export const GET_BANNER_DETAILS_START = "GET_BANNER_DETAILS_START";
export const GET_BANNER_DETAILS_SUCCESS = "GET_BANNER_DETAILS_SUCCESS";
export const GET_BANNER_DETAILS_ERROR = "GET_BANNER_DETAILS_ERROR";

// getPrimaryFilter
export const GET_PRIMARY_FILTER_START = "GET_PRIMARY_FILTER_START";
export const GET_PRIMARY_FILTER_SUCCESS = "GET_PRIMARY_FILTER_SUCCESS";
export const GET_PRIMARY_FILTER_ERROR = "GET_PRIMARY_FILTER_ERROR";

// getSchemeDetails
export const GET_SCHEME_DETAILS_START = "GET_SCHEME_DETAILS_START";
export const GET_SCHEME_DETAILS_SUCCESS = "GET_SCHEME_DETAILS_SUCCESS";
export const GET_SCHEME_DETAILS_ERROR = "GET_SCHEME_DETAILS_ERROR";

// AdvanceFilterData
export const ADVANCE_FILTER_DATA_ADD = "ADVANCE_FILTER_DATA_ADD";

//getAllQuestionnaires
export const GET_QUESTION_START = "GET_QUESTION_START";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_ERROR = "GET_QUESTION_ERROR";

//Scheme steps count
export const NEXT_STEP = "NEXT_STEP";
export const BACK_STEP = "BACK_STEP";

//checkUserQuestionnairesPresent

export const CHECK_USER_QUESTION_PRESENT_START =
  "CHECK_USER_QUESTION_PRESENT_START";
export const CHECK_USER_QUESTION_PRESENT_SUCCESS =
  "CHECK_USER_QUESTION_PRESENT_SUCCESS";
export const CHECK_USER_QUESTION_PRESENT_ERROR =
  "CHECK_USER_QUESTION_PRESENT_ERROR";

//Step value data store
export const STEPS_VALUE_SAVE = "STEPS_VALUE_SAVE";

//getAdvanceFilterSchemeDetails
export const GET_ADVANCE_FILTER_SCHEME_DETAILS_START =
  "GET_ADVANCE_FILTER_SCHEME_DETAILS_START";
export const GET_ADVANCE_FILTER_SCHEME_DETAILS_SUCCESS =
  "GET_ADVANCE_FILTER_SCHEME_DETAILS_SUCCESS";
export const GET_ADVANCE_FILTER_SCHEME_DETAILS_ERROR =
  "GET_ADVANCE_FILTER_SCHEME_DETAILS_ERROR";

//Single category selected
export const SINGLE_SELECTED_CATEGORY = "SINGLE_SELECTED_CATEGORY";

//getEligibilityQuestion
export const GET_ELIGIBILITY_QUESTION_START = "GET_ELIGIBILITY_QUESTION_START";
export const GET_ELIGIBILITY_QUESTION_SUCCESS =
  "GET_ELIGIBILITY_QUESTION_SUCCESS";
export const GET_ELIGIBILITY_QUESTION_ERROR = "GET_ELIGIBILITY_QUESTION_ERROR";
export const GET_ELIGIBILITY_QUESTION_REMOVE =
  "GET_ELIGIBILITY_QUESTION_REMOVE";

//checkUserEligibility save
export const CHECK_USER_ELIGIBILITY_SAVE_START =
  "CHECK_USER_ELIGIBILITY_SAVE_START";
export const CHECK_USER_ELIGIBILITY_SAVE_SUCCESS =
  "CHECK_USER_ELIGIBILITY_SAVE_SUCCESS";
export const CHECK_USER_ELIGIBILITY_SAVE_ERROR =
  "CHECK_USER_ELIGIBILITY_SAVE_ERROR";

export const GET_DETAILED_SCHEME_DETAILS_START =
  "GET_DETAILED_SCHEME_DETAILS_START";
export const GET_DETAILED_SCHEME_DETAILS_SUCCESS =
  "GET_DETAILED_SCHEME_DETAILS_SUCCESS";
export const GET_DETAILED_SCHEME_DETAILS_ERROR =
  "GET_DETAILED_SCHEME_DETAILS_ERROR";
export const GET_DETAILED_SCHEME_DETAILS_REMOVE =
  "GET_DETAILED_SCHEME_DETAILS_REMOVE";
//searchSchemeByValue
export const SEARCH_SCHEME_BY_VALUE_START = "SEARCH_SCHEME_BY_VALUE_START";
export const SEARCH_SCHEME_BY_VALUE_SUCCESS = "SEARCH_SCHEME_BY_VALUE_SUCCESS";
export const SEARCH_SCHEME_BY_VALUE_ERROR = "SEARCH_SCHEME_BY_VALUE_ERROR";
export const TYPE_REMOVE_SEARCH_FILTER = "TYPE_REMOVE_SEARCH_FILTER";
// Get Value of the Village Name
export const GET_VILLAGE_NAME_START = "GET_VILLAGE_NAME_START";
export const GET_VILLAGE_NAME_SUCCESS = "GET_VILLAGE_NAME_SUCCESS";
export const GET_VILLAGE_NAME_ERROR = "GET_VILLAGE_NAME_ERROR";
export const SET_VILLAGE_NAMES = "SET_VILLAGE_NAMES";
export const REMOVE_VILLAGE_NAMES = "REMOVE_VILLAGE_NAMES";

// Get the Social Medias
export const GET_SOCIAL_MEDIA_START = "GET_SOCIAL_MEDIA_START";
export const GET_SOCIAL_MEDIA_SUCCESS = "GET_SOCIAL_MEDIA_SUCCESS";
export const GET_SOCIAL_MEDIA_ERROR = "GET_SOCIAL_MEDIA_ERROR";

// Update the Social Medias
export const UPDATE_SOCIAL_MEDIA_START = "UPDATE_SOCIAL_MEDIA_START";
export const UPDATE_SOCIAL_MEDIA_SUCCESS = "UPDATE_SOCIAL_MEDIA_SUCCESS";
export const UPDATE_SOCIAL_MEDIA_ERROR = "UPDATE_SOCIAL_MEDIA_ERROR";
export const REMOVE_SCHEME_DATA = "REMOVE_SCHEME_DATA";
export const EMPTY_SCHEME_QUESTIONS= "EMPTY_SCHEME_QUESTIONS";
export const DESTROY_SESSION="DESTROY_SESSION";
export const SAVE_CONSENT = "SAVE_CONSENT";
export const EMAIL_OTP_VERIFIED= "EMAIL_OTP_VERIFIED";
export const PDS_TRANSACTIONAL_DATA_SUCCESS ="PDS_TRANSACTIONAL_DATA_SUCCESS";
export const PDS_TRANSACTIONAL_DATA_ERROR = "PDS_TRANSACTIONAL_DATA_ERROR";

//EPETTAGAM
export const EPETTAGAM_VERIFIED= "EPETTAGAM_VERIFIED";

//SUMMARY
export const SUMMARY_REQUEST_ERROR = "SUMMARY_REQUEST_ERROR";
export const SUMMARY_REQUEST_SUCCESS = "SUMMARY_REQUEST_SUCCESS";
export const SUMMARY_REQUEST_START = "SUMMARY_REQUEST_START";

// Beneficiary Details Village wise

export const SCHEME_STAT_BENEFICIARY_Village_START = "SCHEME_STAT_BENEFICIARY_Village_START"
export const SCHEME_STAT_BENEFICIARY_Village_SUCCESS ="SCHEME_STAT_BENEFICIARY_Village_SUCCESS"
export const SCHEME_STAT_BENEFICIARY_Village_ERROR ="SCHEME_STAT_BENEFICIARY_Village_ERROR"

// Sheme Stats with District Wise

export const SCHEME_STAT_DEPARTMENT_District_START = "SCHEME_STAT_DEPARTMENT_District_START"
export const SCHEME_STAT_DEPARTMENT_District_SUCCESS = "SCHEME_STAT_DEPARTMENT_District_SUCCESS"
export const SCHEME_STAT_DEPARTMENT_District_ERROR = "SCHEME_STAT_DEPARTMENT_District_ERROR"

// Scheme stats with Taluk Wise

export const SCHEME_STAT_DEPARTMENT_Taluk_START = "SCHEME_STAT_DEPARTMENT_Taluk_START" 
export const SCHEME_STAT_DEPARTMENT_Taluk_SUCCESS = "SCHEME_STAT_DEPARTMENT_Taluk_SUCCESS" 
export const SCHEME_STAT_DEPARTMENT_Taluk_ERROR = "SCHEME_STAT_DEPARTMENT_Taluk_ERROR" 

// Scheme stats with Village Wise

export const SCHEME_STAT_DEPARTMENT_Village_START="SCHEME_STAT_DEPARTMENT_Village_START"
export const SCHEME_STAT_DEPARTMENT_Village_SUCCESS="SCHEME_STAT_DEPARTMENT_Village_SUCCESS"
export const SCHEME_STAT_DEPARTMENT_Village_ERROR="SCHEME_STAT_DEPARTMENT_Village_ERROR"

// SCheme Stats

export const SCHEME_STAT_DEPARTMENT_START = "SCHEME_STAT_DEPARTMENT_START"
export const SCHEME_STAT_DEPARTMENT_SUCCESS = "SCHEME_STAT_DEPARTMENT_SUCCESS"
export const SCHEME_STAT_DEPARTMENT_ERROR = "SCHEME_STAT_DEPARTMENT_ERROR"