import {
    FAMILY_BENEFITS_AVAILED_REQUEST_ERROR,
    FAMILY_BENEFITS_AVAILED_REQUEST_SUCCESS,
    FAMILY_BENEFITS_AVAILED_REQUEST_START,
    BENEFITS_AVAILED_REQUEST_UPDATE
  } from "../contants/action-types";
  
  const initialState = {
    data: [],
    filteredData: [],
    familyMembersList: null,
    familyLoader: false,
    loading: false,
    error: "",
    pdsData:[]
  };
  
  const familyBenefitsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FAMILY_BENEFITS_AVAILED_REQUEST_START:
        return {
          ...state,
          loading: true,
        };
      case FAMILY_BENEFITS_AVAILED_REQUEST_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case FAMILY_BENEFITS_AVAILED_REQUEST_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case BENEFITS_AVAILED_REQUEST_UPDATE:
        return {
          ...state,
          loading: false,
          filteredData: action.payload,
        };
      default:
        return state;
    }
  };
  export default familyBenefitsReducer;
  